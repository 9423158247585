// Necessary if using App Router to ensure this file runs on the client
"use client";

import { datadogRum } from "@datadog/browser-rum";

// get environment name
const env = process.env.NEXT_PUBLIC_ENV || "local";

// calculate git info
const commitHash = process.env.NEXT_PUBLIC_COMMIT_HASH;
const branchName = process.env.NEXT_PUBLIC_BRANCH_NAME;

if (env !== "local") {
  // Initialize Datadog RUM
  datadogRum.init({
    env,
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: "mlb-select",
    version: `${branchName}-${commitHash}`,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask-user-input"
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
