"use client";

import HttpInterceptor from "@/api/HttpInterceptor";
import Navigation from "@/components/Navigation";
import { ReactProps } from "@/types/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  AlertProvider,
  AlertsFromProvider,
  BCR,
  LoadingFromProvider,
  LoadingProvider,
  Navy,
  ThemeType
} from "best-common-react";
import { useState } from "react";
import { LookupProvider } from "./LookupContext";
import { UserProvider } from "./UserContext";

const theme: ThemeType = {
  ...Navy,
  modal: {
    ...Navy.modal,
    backdrop: "#000000",
    backdropOpacity: 0.5
  }
};

const Providers = ({ children }: ReactProps) => {
  // hooks
  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false
        }
      }
    })
  );

  return (
    <BCR hideContainer theme={theme}>
      <QueryClientProvider client={client}>
        <LoadingProvider>
          <AlertProvider>
            <UserProvider publicPaths={["/signup"]}>
              <LookupProvider>
                <HttpInterceptor>
                  <Navigation />
                  <main>
                    <AlertsFromProvider />
                    <LoadingFromProvider />
                    {children}
                  </main>
                </HttpInterceptor>
              </LookupProvider>
            </UserProvider>
          </AlertProvider>
        </LoadingProvider>
      </QueryClientProvider>
    </BCR>
  );
};

export default Providers;
