"use client";

import { useUser } from "@/contexts/UserContext";
import { ReactProps } from "@/types/core";
import { AxiosError } from "axios";
import { selectApi } from "./SelectApi";

const AUTH_ERROR_CODES = new Set([401, 403]);

const HttpInterceptor = ({ children }: ReactProps) => {
  // hooks
  const { dispatch } = useUser();

  // response interceptors
  selectApi.interceptors.response.clear();
  selectApi.interceptors.response.use(
    resp => {
      // handle XML Redirects
      const data = resp?.data || null;
      if (typeof data === "string" && data.trim().startsWith("<html")) {
        location.href = process.env.NEXT_PUBLIC_SAML_LOGIN_URL || "";
      }

      // always return
      return resp || null;
    },
    (err: AxiosError) => {
      if (!!err.response && AUTH_ERROR_CODES.has(err.response.status)) {
        dispatch({
          type: "updateFields",
          loggedIn: false
        });
      }
      return Promise.reject(err);
    }
  );

  return <>{children}</>;
};

export default HttpInterceptor;
