"use client";

import { RouteConstants } from "@/constants";
import { useUser } from "@/contexts/UserContext";
import { EnvironmentType, NavigationConfigType, NavigationV2 } from "best-common-react";
import Link from "next/link";
import React, { AnchorHTMLAttributes, useMemo } from "react";

const env = (process.env.NEXT_PUBLIC_ENV || "local") as EnvironmentType;

const CustomAnchor = ({ href, ...rest }: React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>) => {
  return <Link href={href || ""} {...rest} />;
};

const Navigation = () => {
  // hooks
  const { loggedIn, userDetails, signOut, isBoc, isTeam, isCandidate } = useUser();

  // constants
  const email = userDetails?.email || "";
  const config: NavigationConfigType = useMemo(() => {
    return {
      env,
      builds: [],
      title: "MLB Select",
      displayEnvIndicator: env !== "prod",
      loggedIn: loggedIn,
      endLogo: {
        logo: "https://www.mlbstatic.com/team-logos/league-on-dark/1.svg",
        visible: true,
        link: "/"
      },
      username: email,
      tabletSupport: false,
      usernameDropdownLinks: loggedIn
        ? [
            {
              id: "mut-sign-out",
              name: "Sign Out",
              onClick: signOut
            }
          ]
        : [],
      navigationLinks: loggedIn
        ? [
            {
              name: "Candidates",
              icon: "fa-user-circle",
              iconCategory: "far",
              to: RouteConstants.SEARCH,
              quickLink: true,
              visible: isTeam || isBoc
            },
            {
              name: "Profile",
              icon: "fa-user-circle",
              iconCategory: "far",
              to: RouteConstants.PROFILE,
              quickLink: true,
              visible: isCandidate
            },
            {
              name: "Positions",
              icon: "fa-map-marker",
              iconCategory: "fas",
              to: RouteConstants.POSITIONS,
              visible: true
            },
            {
              name: "Track",
              icon: "fa-truck-moving",
              iconCategory: "fas",
              to: RouteConstants.TRACK,
              quickLink: true,
              visible: true
            },
            {
              name: "Events",
              icon: "fa-calendar-alt",
              iconCategory: "fas",
              to: RouteConstants.EVENTS,
              quickLink: true,
              visible: true
            },
            {
              name: "Reports",
              icon: "fa-paperclip",
              iconCategory: "fas",
              to: RouteConstants.REPORTS.BASE,
              visible: true,
              subLinks: [
                {
                  name: "Candidate Info",
                  to: RouteConstants.REPORTS.CANDIDATE_INFO
                },
                {
                  name: "Candidate Progress",
                  to: RouteConstants.REPORTS.CANDIDATE_PROGRESS
                },
                {
                  name: "Club Performance",
                  to: RouteConstants.REPORTS.CLUB_PERFORMANCE,
                  visible: isBoc
                },
                {
                  name: "Geographical Info",
                  to: RouteConstants.REPORTS.GEOGRAPHICAL_INFO
                }
              ]
            },
            // {
            //   name: "Notifications",
            //   icon: "fa-bell",
            //   iconCategory: "fas",
            //   to: RouteConstants.NOTIFICATIONS,
            //   visible: true
            // },
            {
              name: "Invitations",
              to: RouteConstants.SETTINGS.INVITATIONS,
              icon: "fa-user-plus",
              iconCategory: "fas",
              visible: isBoc
            },
            {
              name: "Recommendations",
              to: RouteConstants.SETTINGS.RECOMMENDATIONS,
              icon: "fa-user-plus",
              iconCategory: "fas",
              visible: isTeam
            }
          ]
        : []
    };
  }, [isBoc, isCandidate, isTeam, loggedIn, signOut, email]);

  return (
    <header>
      <NavigationV2 config={config} customLinkComponent={CustomAnchor} />
    </header>
  );
};

export default Navigation;
